import axios from "axios";
import { getAppSettings } from "./app-settings";
import storage from "../helpers/storage";

export const logenAxiosInstance = axios.create({
  baseURL: getAppSettings().apiUrl,
});

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: getAppSettings().apiUrl,
});

// Retry Queue and Processing State
const retryQueue = [];
let isProcessing = false;

// Delay helper
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

// Function to process the retry queue sequentially
const processQueue = async () => {
  if (isProcessing) return; // Prevent multiple processors
  isProcessing = true;

  while (retryQueue.length > 0) {
    const { config, resolve, reject } = retryQueue.shift();

    let retry = true;

    while (retry) {
      try {
        const response = await axios.request(config); // Use axios.request to avoid nested interceptors
        resolve(response); // Resolve the promise if successful
        retry = false; // Stop retrying after a successful request
      } catch (error) {
        if (error.response?.status === 429) {
          // If rate-limited, wait and retry
          await delay(750); // Adjust delay as needed
        } else {
          // If not a 429 error, reject the promise
          reject(error);
          retry = false;
        }
      }
    }
  }

  isProcessing = false;
};

// Interceptor to handle 429 errors
axiosInstance.interceptors.response.use(
  (response) => response, // Pass through successful responses
  async (error) => {
    if (error.response?.status === 429) {
      // Add the request to the retry queue
      return new Promise(async (resolve, reject) => {
        retryQueue.push({ config: error.config, resolve, reject });
        processQueue(); // Start processing the queue
      });
    }

    return Promise.reject(error); // Reject other errors
  }
);

// Interceptor to handle authentication
axiosInstance.interceptors.request.use(async (request) => {
  const isLoggedIn = storage().getFromLocalStorage("loggedIn");
  if (!isLoggedIn) {
    window.location.reload();
  }
  return request;
});

export default axiosInstance;
