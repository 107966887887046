import { getAppSettings } from "../config/app-settings";
import axiosInstance from "../config/axios-config";

const limit = 100;

export const getAllAgileProjects = (filter = {}) => {
  var projects = [];
  var result = 0;
  var page = 1;
  return appendNewProjectsFromServer(projects, result, page, filter);
};

const getProjects = (page, filter = {}) => {
  var body = {
    limit: limit,
    page: page,
    api_key: getAppSettings().apiKey,
  };

  if (filter.is_archive && filter.is_archive.length === 1) {
    body["filter[is_archive]"] = filter.is_archive.toString();
  }
  // if (filter.start_date && filter.end_date) {
  //     body["filter[startdate]"] = {
  //         start_date: filter.from_date,
  //         end_date: filter.to_date,
  //     }
  // }
  // else if (filter.from_date) {
  //     body["filter[startdate]"] = filter.from_date;
  // }
  // else if(filter.to_date) {
  //     body["filter[enddate]"] = filter.to_date;
  // }
  return axiosInstance
    .get(`${getAppSettings().apiUrl}/agile/projects/list`, {
      params: body,
    })
    .then((response) => {
      return response.data.response;
    })
    .catch((error) => {
      console.log(error);
    });
};

const appendNewProjectsFromServer = (projects, result, page, filter) => {
  return getProjects(page, filter).then((response) => {
    if (response) {
      response.items.map((project) => {
        return projects.push(project);
      });

      result = result + response.count;
      page = page + 1;

      if (result !== parseInt(response.total_result)) {
        return appendNewProjectsFromServer(projects, result, page, filter);
      }
      return projects;
    }
  });
};
