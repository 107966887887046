import { getAppSettings } from "../config/app-settings";
import axiosInstance from "../config/axios-config";
import { addDays, addYears, dateFormat } from "../helpers/date-time-management";

const limit = 100;

export const getEmployeeTimeLog = (filter = {}) => {
  var timeLogs = [];
  var result = 0;
  var page = 1;
  return appendNewTimeLogFromServer(timeLogs, result, page, filter);
};

const getTimeLog = (page, filter) => {
  var body = {
    limit: limit,
    page: page,
    api_key: getAppSettings().apiKey,
    // this filter didn't work because of the API limitations so we filtered locally
    // "filter[timesheet_module]": "task"
  };
  if (filter.employees && filter.employees.length !== 0) {
    body["filter[user_id]"] = filter.employees
      .map((employee) => employee.value)
      .toString();
  }
  if (filter.from_date || filter.to_date) {
    body["filter[created_date]"] = {
      // when we update the time spent, the created_date will be affected not the updated_date
      start_date: filter.from_date
        ? filter.from_date
        : dateFormat(addYears(filter.to_date, -1), "YYYY-MM-DD"),
      end_date: filter.to_date
        ? dateFormat(addDays(filter.to_date, 1), "YYYY-MM-DD")
        : dateFormat(addYears(filter.from_date, 1), "YYYY-MM-DD"),
      // we added one day to the to_date because the end_date is not included in the filter
    };
  }
  if (filter.timesheet_module)
    body["filter[timesheet_module]"] = filter.timesheet_module;

  return axiosInstance
    .get(`${getAppSettings().apiUrl}/timetracker/timelogs/list`, {
      params: body,
    })
    .then((response) => {
      return response.data.response;
    })
    .catch((error) => {
      console.log(error);
    });
};

const appendNewTimeLogFromServer = (timeLogs, result, page, filter) => {
  return getTimeLog(page, filter).then((response) => {
    if (response) {
      response.items.map((timeLog) => {
        return timeLogs.push(timeLog);
      });

      result = result + response.count;
      page = page + 1;

      if (result !== parseInt(response.total_result)) {
        return appendNewTimeLogFromServer(timeLogs, result, page, filter);
      }
      return timeLogs;
    }
  });
};
