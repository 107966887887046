export const admins = [
  "168421", // Cynthia
  "168420", // Joey
  "172269", // Rawad
  "176942", // Tarek
  "168599", // Ziad
  "174105", // Samar
  "242547", // Chaza
  "183879", // Chady
];
